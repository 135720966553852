import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

const WhatsAppButton: React.FC = () => {
    const handleWhatsAppClick = () => {
        const phoneNumber = '+919532119007'; // Replace with your actual phone number
        const message = encodeURIComponent('Hello! I would like to know more about your services.');
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${message}`;

        window.open(whatsappURL, '_blank');
    };

    return (
        <div className="whatsapp-button" onClick={handleWhatsAppClick}>
            <FaWhatsapp />
        </div>
    );
};

export default WhatsAppButton;
