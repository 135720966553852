import React from 'react'

const Loader = () => {
    return (
        <>
            {/* <div className="infinity-spinner-wrapper">
                <div className="infinity-spinner">
                    <div className="spinner-loop loop-1"></div>
                    <div className="spinner-loop loop-2"></div>
                </div>
                <p className="spinner-text">Loading...</p>
            </div> */}

            <div className="infinity-spinner-container">
                <div className="infinity-spinner"></div>
                <p className="loading-text">Loading...</p>
            </div>

            {/* <div className="loading-container">
                <div className="spinner"></div>
                <p className="loading-text">Loading...</p>
            </div> */}
            {/* 
             <div className="loading-container">
                <div className="loading-spinner">Loading...</div>
            </div> */}
        </>
    )
}

export default Loader