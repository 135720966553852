import React, { useState, useEffect } from 'react';
import { FaCookieBite } from 'react-icons/fa'; // Cookie icon
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'; // Check and Close icons
import { ROUTER_URL_CONSTANT } from '../constants/routerUrlConstant';
import { Link } from 'react-router-dom';

interface CookieConsentProps {
    onAccept?: () => void;
    onReject?: () => void;
}

const CookieConsent: React.FC<CookieConsentProps> = ({ onAccept, onReject }) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);

    useEffect(() => {
        const consent = localStorage.getItem('cookieConsent');
        if (!consent) {
            const timer = setTimeout(() => {
                setIsVisible(true);
            }, 3000); // Show modal after 3 seconds

            // Cleanup timer on component unmount
            return () => clearTimeout(timer);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'accepted');
        setIsVisible(false);
        if (onAccept) onAccept();
    };

    const handleReject = () => {
        localStorage.setItem('cookieConsent', 'rejected');
        setIsVisible(false);
        if (onReject) onReject();
    };

    if (!isVisible) return null;

    return (
        <div className="cookie-consent">
            <div className="cookie-consent-content">
                <FaCookieBite className="cookie-icon" />
                <p>
                    We use cookies to ensure you get the best experience on our website. By continuing to browse, you agree to our use of cookies. For more details, see our <Link to={ROUTER_URL_CONSTANT.PRIVACY_POLICY}>Privacy Policy</Link>.
                </p>
                <div className="cookie-consent-buttons">
                    <button onClick={handleAccept} className='AcceptCookies'>
                        <AiOutlineCheck className="button-icon" /> Accept Cookies
                    </button>
                    <button onClick={handleReject} className='DeclineCookies'>
                        <AiOutlineClose className="button-icon" /> Decline
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CookieConsent;
