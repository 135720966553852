// components/ChatSupportButton.tsx
import React, { useEffect } from 'react';
import { FaComments } from 'react-icons/fa';

const ChatSupportButton: React.FC = () => {
    useEffect(() => {
        // Initialize Tawk.to chat script
        const tawkScript = document.createElement('script');
        tawkScript.src = 'https://embed.tawk.to/66cbfb5250c10f7a00a06130/1i66crfh7'; // Replace with your Tawk.to script URL
        tawkScript.async = true;
        tawkScript.charset = 'UTF-8';
        tawkScript.setAttribute('crossorigin', '*');
        document.body.appendChild(tawkScript);

        return () => {
            // Cleanup the script if the component unmounts
            document.body.removeChild(tawkScript);
        };
    }, []);

    // tickets @oglitz-software.p.tawk.email

    return (
        <>
        </>
        // <div className="chat-support-button">
        //     <FaComments />
        // </div>
    );
};

export default ChatSupportButton;
