import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App'
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { gmtParams } from './constants/validationConstant';


const gtmId = 'GTM-MT55JVQS'; // Your GTM ID


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <GTMProvider state={gmtParams}>
      <App />
    </GTMProvider>
  </React.StrictMode>
);
