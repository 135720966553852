// App.tsx
import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ROUTER_URL_CONSTANT } from './constants/routerUrlConstant';
import Loader from './components/Loader';
import CookieConsent from './components/CookieConsent';
import OfferModal from './components/OfferModal';
import SocialMediaSidebar from './components/SocialMediaSidebar';
import WhatsAppButton from './components/WhatsAppButton';
import ChatSupportButton from './components/ChatSupportButton';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { gmtParams } from './constants/validationConstant';
import GoogleAnalytics from './GoogleAnalytics';

const Aboutus = lazy(() => import('./components/Aboutus'));
const CustomerSupport = lazy(() => import('./components/CustomerSupport'));
const TermsAndConditions = lazy(() => import('./pages/TermsAndConditions'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const NotFound = lazy(() => import('./pages/NotFound'));
const Navbar = lazy(() => import('./components/Navbar'));
const Home = lazy(() => import('./components/Home'));
const Footer = lazy(() => import('./components/Footer'));
const ContactPage = lazy(() => import('./components/ContactPage'));

function App() {
  const handleAcceptCookies = () => {
    // Handle any actions needed when cookies are accepted
  };

  const handleRejectCookies = () => {
    // Handle any actions needed when cookies are rejected
  };

  return (
    <GTMProvider state={gmtParams}>
      <Suspense fallback={<Loader />}>
        <Router>
          <Navbar />
          <Routes>
            <Route path={ROUTER_URL_CONSTANT.MAIN} element={<Home />} />
            <Route path={ROUTER_URL_CONSTANT.CONTACT_US_PAGE} element={<ContactPage />} />
            <Route path={ROUTER_URL_CONSTANT.ABOUT_US} element={<Aboutus />} />
            <Route path={ROUTER_URL_CONSTANT.CUSTOMER_SUPPORT} element={<CustomerSupport />} />
            <Route path={ROUTER_URL_CONSTANT.TERMS_AND_CONDITIONS} element={<TermsAndConditions />} />
            <Route path={ROUTER_URL_CONSTANT.PRIVACY_POLICY} element={<PrivacyPolicy />} />
            <Route path="*" element={<NotFound />} /> {/* Catch-all for 404 pages */}
          </Routes>
          <Footer />
          <CookieConsent
            onAccept={handleAcceptCookies}
            onReject={handleRejectCookies}
          />
          <OfferModal />
          <SocialMediaSidebar />
          <WhatsAppButton />
          <ChatSupportButton />
          <GoogleAnalytics />
        </Router>
      </Suspense>
    </GTMProvider>
  );
}

export default App;
