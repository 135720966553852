export const passwordTooltipRegex: any = {
  digits: /^(?=.*[0-9]).+$/,
  lowerCase: /^(?=.*[a-z]).+$/,
  upperCase: /^(?=.*[A-Z]).+$/,
  specialChar: /^(?=.*[~!@#$%&_+,;*]).+$/,
  charLength: /^.{9,16}$/,
};
export const passwordRegex: RegExp =
  /^(?=.*[0-9])(?=.*[!@#;$%^&*])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%;^&*]{9,16}$/;
export const phoneRegex: RegExp = /^(\+91-|\+91|0)?\d{10}$/;
export const emailRegex: RegExp =
  /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{3})+$/;
export const userIdRegex: RegExp = /^[0-9]{8,10}$/;
export const PersonalExpRegex: RegExp = /^[0-9.]+$/;
export const onlyAlphabets: RegExp = /^[a-zA-Z ]+$/;
export const cityExp: RegExp = /^[a-zA-Z .]+$/;
export const alphaNumreic: RegExp = /^[a-zA-Z0-9\-\s]*$/;
export const pinCodeRegex: RegExp = /^[0-9]{6}$/;
export const sameDigitsnotRepeating: RegExp = /(\d)\1{9}$/;

export type posotion =
  | "top left"
  | "top right"
  | "bottom right"
  | "bottom left"
  | "right center"
  | "left center"
  | "top center"
  | "bottom center"
  | undefined;

  // const gtmId = 'GTM-MT55JVQS'; // Your GTM ID
export const gmtParams = {
  id: "GTM-MT55JVQS",
  dataLayer: {
    gtmstart: new Date().getTime(),
    event: "gtm.js",
  },
};
