import React, { useState, useEffect } from 'react';
import { FaGift } from 'react-icons/fa'; // Import fallback icon

const OFFER_MODAL_KEY = 'offerModalShown';

const OfferModal: React.FC = () => {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [imageError, setImageError] = useState<boolean>(false);

    useEffect(() => {
        const modalShown = localStorage.getItem(OFFER_MODAL_KEY);
        if (!modalShown) {
            const timer = setTimeout(() => {
                setIsVisible(true);
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, []);

    const handleAction = () => {
        localStorage.setItem(OFFER_MODAL_KEY, 'true');
        setIsVisible(false);
    };

    const handleImageError = () => {
        setImageError(true);
    };

    if (!isVisible) return null;

    return (
        <div className="offer-modal">
            <div className="offer-modal-content">
                {!imageError ? (
                    <img
                        src="/path-to-offer-image.jpg"
                        alt="Special Offer"
                        className="offer-image"
                        onError={handleImageError}
                    />
                ) : (
                    <div className="offer-icon">
                        <FaGift size={80} color="#f08614" />
                    </div>
                )}
                <div className="offer-details">
                    <h2>Exclusive Offer</h2>
                    <p>Get 20% off on all products! Limited time offer. Shop now to save big.</p>
                    <div className="offer-buttons">
                        <button className="action-button primary-button" onClick={handleAction}>Shop Now</button>
                        <button className="action-button secondary-button" onClick={handleAction}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OfferModal;
