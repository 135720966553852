import React, { useEffect } from 'react';

const GA_TRACKING_ID = 'G-XZB11SMF61'; // Replace with your Measurement ID

const GoogleAnalytics: React.FC = () => {
    useEffect(() => {
        const handleRouteChange = (url: string) => {
            // Call gtag to track page views
            window.gtag('config', GA_TRACKING_ID, {
                page_path: url,
            });
        };

        // Handle initial page load
        handleRouteChange(window.location.pathname + window.location.search);

        // Listen for changes to the route (for single-page applications)
        window.addEventListener('popstate', () => handleRouteChange(window.location.pathname + window.location.search));

        return () => {
            // Cleanup event listener
            window.removeEventListener('popstate', () => handleRouteChange(window.location.pathname + window.location.search));
        };
    }, []);

    return null;
};

export default GoogleAnalytics;
