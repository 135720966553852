export const ROUTER_URL_CONSTANT = {
  MAIN: "/",
  CONTACT_US_PAGE: "/contact-us",
  ABOUT_US: "/about-us",
  CUSTOMER_SUPPORT: "/customer-support",
  MAIN_SITE: "https://www.oglitz.com",
  TECH_BLOGS: "/tech-blogs",
  OGTLIZ_MARKETPLACE: "/ogltiz-marketplace",
  OGTLIZ_TECH_TOOLS: "/ogltiz-tech-tools",
  OGTLIZ_TECH_SOLUTIONS: "/ogltiz-tech-solutions",
  OGTLIZ_QUOTES: "/ogltiz-quotes",
  OGTLIZ_TECH_RESOURCES: "/ogltiz-tech-resources",
  OGTLIZ_JOBS: "/ogltiz-jobs",
  TERMS_AND_CONDITIONS: "/terms-and-conditions",
  PRIVACY_POLICY: "/privacy-policy",
};

export const APP_CONSTANTS = {
  OTP_EXP_TIME: 60,
  auth_token: "token",
  USER_SESSION: "userData",
};
